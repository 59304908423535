import React from 'react'
import { Link } from 'gatsby'

const toolbar = props => (
  <header className="bg-primary h-16">
    <nav className="flex p-4">
      <div></div>
      <div className="text-white px-4">
        <Link to="/">Wayward Media</Link>
      </div>
      <div className="flex text-white">
        <Link className="block px-2 hover:bg-primarylighter" to="/about">
          About
        </Link>

        <Link className="block px-2 hover:bg-primarylighter" to="/portfolio">
          Portfolio
        </Link>

        <Link className="block px-2 hover:bg-primarylighter" to="/services">
          Services
        </Link>

        <Link className="block px-2 hover:bg-primarylighter" to="/articles">
          Articles
        </Link>

        <Link className="block px-2 hover:bg-primarylighter" to="/contact">
          Contact
        </Link>
      </div>
    </nav>
  </header>
)

export default toolbar
