import React from 'react'
import { Seo } from '../components/Seo/Seo'
// import Navigation from '../components/Navigation'
import Toolbar from '../components/Toolbar/Toolbar'
import '../css/style.css'

export default ({ children }) => (
  <div className="mx-auto">
    <Seo />
    <Toolbar />
    <div className="container color-red mx-auto p-4">{children}</div>
  </div>
)
