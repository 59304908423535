import React from 'react'
import Helmet from 'react-helmet'

export function Seo({ url, pathname, title, description }) {

  return (
      <Helmet
          title={title}
          meta={[
            { name: 'description', content: description },
            { name: 'keywords', content: 'Web Site Design, Digital Solutions, Halifax,' +
              ' Calderdale, West Yorkshire' },

            { name: 'geo.position', content: '53.706709053;-1.91341101' },
            { name: 'geo.region', content: 'GB' },
            { name: 'geo.placename', content: 'Sowerby Bridge, United' +
              ' Kingdom' },
            { name: 'ICBM', content: '53.706709053, -1.91341101' },
            { name: 'DC.title', content: 'Wayward Media' },

            { property: 'og:site_name', content: 'Wayward Media' },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:type', content: 'article' },
            { property: 'og:locale', content: 'en_GB' },
            { property: 'og:latitude', content: '53.706709053' },
            { property: 'og:longitude', content: '-1.91341101' },
            { property: 'og:locality', content: 'Wayward Media' },
            { property: 'og:region', content: 'West Yorkshire' },
            { property: 'og:country-name', content: 'UK' },

            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@waywardmedia' },
            { name: 'twitter:creator', content: '@waywardmedia' },
            { name: 'twitter:description', content: description },


          ]}
      />
  );
}


Seo.defaultProps = {
  url: 'https://www.waywardmedia.co.uk.co.uk',
  pathname: '',
  title: 'Wayward Media Website Design Services Sowerby Bridge',
  description: 'Wayward Media provides bespoke web solutions for clients in the UK.'
};
